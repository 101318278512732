.am-modal-mask {
  background: rgba(0, 0, 0, 0.9) !important;
}

.am-modal-content {
  background: #ffc408 !important;
}

.am-modal {
  border-radius: 20px !important;
}

.am-modal-content {
  overflow: visible !important;
}

.am-modal-body {
  color: "#212121" !important;
}

.am-modal-transparent {
  width: 80% !important;
}

.am-modal-transparent .am-modal-content {
  border-radius: 20px !important;
  padding-top: 5px !important;
}

.am-modal-transparent .am-modal-content .am-modal-body {
  padding: 0 15px 0 !important;
}

.scrollbar-hidden .am-modal-header {
  padding: 0;
  border-bottom: 1px solid rgba(33, 33, 33, 0.1);
  margin-bottom: 15px;
}

.am-modal-body {
  color: #212121;
  font-family: "Poppins";
}

.am-modal-button-group-v .am-modal-button {
  height: 70px !important;
  line-height: 70px !important;
  color: #212121;
  font-size: 19px;
  font-weight: 700;
  font-family: "Poppins";
}

.am-modal-button-active {
  background: rgba(33, 33, 33, 0.1) !important;
  border-radius: 0 0 20px 20px !important;
}

html:not([data-scale]) .am-modal-button-group-v .am-modal-button::before {
  background: rgba(33, 33, 33, 0.1) !important;
}

.skeleton {
  /* opacity: 0.7; */
  animation: skeleton-loading 1s linear infinite alternate;
}
.skeleton-text {
  width: 100%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}
.skeleton-text:last-child {
  margin-bottom: 0;
  width: 80%;
}
@keyframes skeleton-loading {
  0% {
    background-color: #4f565b;
  }
  100% {
    background-color: #30383e;
  }
}

.disclaimer-hand-image {
  background: white;
  padding: 15px;
  height: 25px;
  width: 25px;
  border-radius: 100px;
  position: absolute;
  top: -20px;
  right: -10px;
  box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
}
