@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  overscroll-behavior-x: none !important;
}

* {
  -webkit-touch-callout: none;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

/* For Toast */
.go2072408551 {
  max-width: 1000px !important;
}

.masked-overflow {
  /* scroll bar width, for use in mask calculations */
  --scrollbar-width: 8px;

  /* mask fade distance, for use in mask calculations */
  --mask-height: 50px;

  /* If content exceeds height of container, overflow! */
  overflow-y: auto;

  /* Our height limit */
  height: 300px;

  /* Need to make sure container has bottom space,
otherwise content at the bottom is always faded out */
  padding-bottom: var(--mask-height);

  /* Keep some space between content and scrollbar */
  padding-right: 20px;

  /* The CSS mask */

  /* The content mask is a linear gradient from top to bottom */
  --mask-image-content: linear-gradient(
    to bottom,
    black var(--mask-height),
    /* transparent, */ black var(--mask-height),
    black calc(100% - var(--mask-height)),
    transparent
  );

  /* Here we scale the content gradient to the width of the container 
minus the scrollbar width. The height is the full container height */
  --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;

  /* The scrollbar mask is a black pixel */
  --mask-image-scrollbar: linear-gradient(black, black);

  /* The width of our black pixel is the width of the scrollbar.
The height is the full container height */
  --mask-size-scrollbar: var(--scrollbar-width) 100%;

  /* Apply the mask image and mask size variables */
  -webkit-mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
          mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  -webkit-mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
          mask-size: var(--mask-size-content), var(--mask-size-scrollbar);

  /* Position the content gradient in the top left, and the 
scroll gradient in the top right */
  -webkit-mask-position: 0 0, 100% 0;
          mask-position: 0 0, 100% 0;

  /* We don't repeat our mask images */
  -webkit-mask-repeat: no-repeat, no-repeat;
          mask-repeat: no-repeat, no-repeat;
}

/* Import MONTSERRAT from Google Font */

html,
p {
  font-family: "Poppins", "Roboto", sans-serif;
}

body.story-content {
  overflow: hidden;
}

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  border-radius: 16px 16px 0 0 !important;
  z-index: 91 !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

/* Desktop Reduce Widthh */

.react-swipeable-view-container {
  /* Here bottom sheet resizing */
  /* max-width: 00px !important ; */
  margin-left: auto !important;
  margin-right: auto !important;
}

.sc-iwyYcG {
  max-width: 414px;
}

.kCBQGk,
.izBVou {
  max-width: 414px !important;
  /* left: 50%;
  transform: translateX(-50%); */
}

/* Rotation of some CustomIcons */
svg.rotate {
  transform: rotate(193deg) scaleX(-1);
}

/* Custom Search Input => 16px to avoid auto zoom on iOS */
.am-search-input input[type="search"] {
  font-size: 16px !important;
  font-family: "Poppins" !important;
}

.am-search-input {
  border-radius: 50px !important;
}

.am-search {
  padding: 20px !important;
  height: 25px !important;
  max-width: 580px !important;
}

.profilePage.am-search {
  padding: 0px !important;
  margin-top: 30px;
  height: 28px !important;
}

.fixedProgressBar {
  position: fixed !important;
  top: 80px;
  z-index: 95;
}

/* THIS ONE */
.controllerScrolled {
  position: fixed !important;
  top: 50px !important;
  width: 100%;
  max-width: 414px !important;
  z-index: 90;
  /* margin-top: 100px; */
  /* transition: margin-top 1s ease-in-out;
  -webkit-transition: margin-top 1s ease-in-out;
  -moz-transition: margin-top 1s ease-in-out; */
}

.fixedProgressPlay {
  position: fixed !important;
  top: 320px;
  z-index: 95;
}

.fixedProgressShare {
  position: fixed !important;
  top: 370px;
  z-index: 95;
}

.fixedProgressMarker {
  position: fixed !important;
  top: 450px;
  z-index: 95;
}

/* Image Viewer Customised */

.cgseTX {
  top: 50px !important;
  background-color: rgba(0, 0, 0, 0.95) !important;
  z-index: 97 !important;
}

.kwpAUB {
  top: 30px !important;
}

/* Bottom Action Sheet Customised */

.react-swipeable-view-container {
  border-radius: 20px 20px 0px 0px !important;
}

/* CheckBox customised */

.am-checkbox.am-checkbox-checked .am-checkbox-inner {
  border-color: #ffc408 !important;
  background: #ffc408 !important;
}

.login-checkbox .am-checkbox-inner {
  border-color: #fff !important;
  background: #ffc408 !important;
}

.login-checkbox .am-checkbox.am-checkbox-checked .am-checkbox-inner {
  border-color: #ce0868 !important;
  background: #ce0868 !important;
}

.checkbox-square .am-checkbox-inner,
.checkbox-square .am-checkbox.am-checkbox-checked .am-checkbox-inner {
  border-radius: 5px !important;
}

/* Loading dots */

.loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #81939c;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 #81939c, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #81939c, 0.5em 0 0 #81939c;
  }
}

/* Read More button */

.clamp-lines__button {
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-family: "Poppins";
  font-size: 16px;
  text-decoration: underline;
  font-weight: 300;
  margin-top: 15px;
  margin-left: 30px;
  font-style: italic;
  color: "#212121";
}

.clamp-lines__button::before {
  content: " ";
  position: absolute;
  border-color: #f42cab !important;
  border-style: solid;
  border-width: 0 0.2em 0.2em 0;
  height: 0.4em;
  left: 20px;
  margin-top: 4px !important;
  transform: rotate(45deg);
  width: 0.4em;
}

.clamp-lines__button[aria-expanded="true"]::before {
  content: " ";
  position: absolute;
  border-color: #f42cab !important;
  border-style: solid;
  border-width: 0 0.2em 0.2em 0;
  height: 0.4em;
  left: 20px;
  margin-top: 7px !important;
  transform: rotate(-135deg);
  width: 0.4em;
}

/* View More Button (Profile) */

.clamp-lines__button_profile {
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  margin: 0;
  padding: 0;
  margin-left: 5px;
  cursor: pointer;
  font-family: "Poppins";
  font-size: 11px;
  font-weight: 400;
  color: #fe5763;
}

.clamp-lines__button_profile::before {
  content: " ";
  position: absolute;
  border-color: #fe5763 !important;
  border-style: solid;
  border-width: 0 0.1em 0.1em 0;
  height: 0.4em;
  right: 45px;
  margin-top: 2px !important;
  transform: rotate(45deg);
  width: 0.4em;
}

.clamp-lines__button_profile[aria-expanded="true"]::before {
  content: " ";
  position: absolute;
  border-color: #fe5763 !important;
  border-style: solid;
  border-width: 0 0.1em 0.1em 0;
  height: 0.4em;
  left: 78px;
  margin-top: 6px !important;
  transform: rotate(-135deg);
  width: 0.4em;
}

/* Fix Search bar bugs */

.am-search-synthetic-ph {
  width: 81px !important;
  padding-left: 15px !important;
}

/* Fix Desktop bug Search Bar */
.am-search {
  max-width: 414px !important;
  margin: 0px auto;
  padding: 20px 0 !important;
}

.am-search-input {
  width: unset !important;
  max-width: 414px !important;
  margin: 0 10px !important;
}

/* Share Bottom Sheet */

section.web-share-fade-in-up {
  max-width: 414px !important;
  width: 100% !important;
}

section.web-share-fade-in-up div {
  padding: 10px 30px !important;
  margin-bottom: 30px;
}

section.web-share-fade-in-up header {
  padding: 30px 30px !important;
}

/* Icon Wrapper Share */

.iconWrapperShare:active {
  background: #ff8800 !important;
}

/* Image Viewer max height */

.img_pc9x2k-o_O-imgLoaded_9vayoh {
  max-height: 70vh !important;
}

/* Animation Shaking Blender */

.shake-blender {
  animation: leaves 0.15s ease-in-out infinite;
  -webkit-animation: leaves 0.15s ease-in-out infinite;
  transform-origin: top left;
}

@keyframes leaves {
  0% {
    transform: rotate(0deg) translate(-50%, -50%);
    -webkit-transform: rotate(0deg) translate(-50%, -50%);
  }

  50% {
    transform: rotate(10deg) translate(-50%, -50%);
    -webkit-transform: rotate(10deg) translate(-50%, -50%);
  }

  75% {
    transform: rotate(-10deg) translate(-50%, -50%);
    -webkit-transform: rotate(-10deg) translate(-50%, -50%);
  }

  100% {
    transform: rotate(0deg) translate(-50%, -50%);
    -webkit-transform: rotate(0deg) translate(-50%, -50%);
  }
}

/* Basic fade in animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Profile Headline */

.profile-headline {
  font-size: 16px;
  color: #212121;
  font-family: "Poppins";
  font-weight: 600;
}

/* To force word to line-return */
.force-wrap {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
}

.am-modal-mask {
  background: rgba(0, 0, 0, 0.9) !important;
}

.am-modal-content {
  background: #ffc408 !important;
}

.am-modal {
  border-radius: 20px !important;
}

.am-modal-content {
  overflow: visible !important;
}

.am-modal-body {
  color: "#212121" !important;
}

.am-modal-transparent {
  width: 80% !important;
}

.am-modal-transparent .am-modal-content {
  border-radius: 20px !important;
  padding-top: 5px !important;
}

.am-modal-transparent .am-modal-content .am-modal-body {
  padding: 0 15px 0 !important;
}

.scrollbar-hidden .am-modal-header {
  padding: 0;
  border-bottom: 1px solid rgba(33, 33, 33, 0.1);
  margin-bottom: 15px;
}

.am-modal-body {
  color: #212121;
  font-family: "Poppins";
}

.am-modal-button-group-v .am-modal-button {
  height: 70px !important;
  line-height: 70px !important;
  color: #212121;
  font-size: 19px;
  font-weight: 700;
  font-family: "Poppins";
}

.am-modal-button-active {
  background: rgba(33, 33, 33, 0.1) !important;
  border-radius: 0 0 20px 20px !important;
}

html:not([data-scale]) .am-modal-button-group-v .am-modal-button::before {
  background: rgba(33, 33, 33, 0.1) !important;
}

.skeleton {
  /* opacity: 0.7; */
  animation: skeleton-loading 1s linear infinite alternate;
}
.skeleton-text {
  width: 100%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}
.skeleton-text:last-child {
  margin-bottom: 0;
  width: 80%;
}
@keyframes skeleton-loading {
  0% {
    background-color: #4f565b;
  }
  100% {
    background-color: #30383e;
  }
}

.disclaimer-hand-image {
  background: white;
  padding: 15px;
  height: 25px;
  width: 25px;
  border-radius: 100px;
  position: absolute;
  top: -20px;
  right: -10px;
  box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
}

body {
    background: #ECF0F5 !important;
}
.journal-modal .am-modal-content {
  background: #ffffff !important;
  padding: 32px 20px;
}

/* .am-modal-mask{ 
  background: #90A4AE !important;
  opacity: 0.8;
} */

