/* Import MONTSERRAT from Google Font */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

html,
p {
  font-family: "Poppins", "Roboto", sans-serif;
}

body.story-content {
  overflow: hidden;
}

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  border-radius: 16px 16px 0 0 !important;
  z-index: 91 !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

/* Desktop Reduce Widthh */

.react-swipeable-view-container {
  /* Here bottom sheet resizing */
  /* max-width: 00px !important ; */
  margin-left: auto !important;
  margin-right: auto !important;
}

.sc-iwyYcG {
  max-width: 414px;
}

.kCBQGk,
.izBVou {
  max-width: 414px !important;
  /* left: 50%;
  transform: translateX(-50%); */
}

/* Rotation of some CustomIcons */
svg.rotate {
  transform: rotate(193deg) scaleX(-1);
}

/* Custom Search Input => 16px to avoid auto zoom on iOS */
.am-search-input input[type="search"] {
  font-size: 16px !important;
  font-family: "Poppins" !important;
}

.am-search-input {
  border-radius: 50px !important;
}

.am-search {
  padding: 20px !important;
  height: 25px !important;
  max-width: 580px !important;
}

.profilePage.am-search {
  padding: 0px !important;
  margin-top: 30px;
  height: 28px !important;
}

.fixedProgressBar {
  position: fixed !important;
  top: 80px;
  z-index: 95;
}

/* THIS ONE */
.controllerScrolled {
  position: fixed !important;
  top: 50px !important;
  width: 100%;
  max-width: 414px !important;
  z-index: 90;
  /* margin-top: 100px; */
  /* transition: margin-top 1s ease-in-out;
  -webkit-transition: margin-top 1s ease-in-out;
  -moz-transition: margin-top 1s ease-in-out; */
}

.fixedProgressPlay {
  position: fixed !important;
  top: 320px;
  z-index: 95;
}

.fixedProgressShare {
  position: fixed !important;
  top: 370px;
  z-index: 95;
}

.fixedProgressMarker {
  position: fixed !important;
  top: 450px;
  z-index: 95;
}

/* Image Viewer Customised */

.cgseTX {
  top: 50px !important;
  background-color: rgba(0, 0, 0, 0.95) !important;
  z-index: 97 !important;
}

.kwpAUB {
  top: 30px !important;
}

/* Bottom Action Sheet Customised */

.react-swipeable-view-container {
  border-radius: 20px 20px 0px 0px !important;
}

/* CheckBox customised */

.am-checkbox.am-checkbox-checked .am-checkbox-inner {
  border-color: #ffc408 !important;
  background: #ffc408 !important;
}

.login-checkbox .am-checkbox-inner {
  border-color: #fff !important;
  background: #ffc408 !important;
}

.login-checkbox .am-checkbox.am-checkbox-checked .am-checkbox-inner {
  border-color: #ce0868 !important;
  background: #ce0868 !important;
}

.checkbox-square .am-checkbox-inner,
.checkbox-square .am-checkbox.am-checkbox-checked .am-checkbox-inner {
  border-radius: 5px !important;
}

/* Loading dots */

.loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #81939c;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 #81939c, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #81939c, 0.5em 0 0 #81939c;
  }
}

/* Read More button */

.clamp-lines__button {
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-family: "Poppins";
  font-size: 16px;
  text-decoration: underline;
  font-weight: 300;
  margin-top: 15px;
  margin-left: 30px;
  font-style: italic;
  color: "#212121";
}

.clamp-lines__button::before {
  content: " ";
  position: absolute;
  border-color: #f42cab !important;
  border-style: solid;
  border-width: 0 0.2em 0.2em 0;
  height: 0.4em;
  left: 20px;
  margin-top: 4px !important;
  transform: rotate(45deg);
  width: 0.4em;
}

.clamp-lines__button[aria-expanded="true"]::before {
  content: " ";
  position: absolute;
  border-color: #f42cab !important;
  border-style: solid;
  border-width: 0 0.2em 0.2em 0;
  height: 0.4em;
  left: 20px;
  margin-top: 7px !important;
  transform: rotate(-135deg);
  width: 0.4em;
}

/* View More Button (Profile) */

.clamp-lines__button_profile {
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  margin: 0;
  padding: 0;
  margin-left: 5px;
  cursor: pointer;
  font-family: "Poppins";
  font-size: 11px;
  font-weight: 400;
  color: #fe5763;
}

.clamp-lines__button_profile::before {
  content: " ";
  position: absolute;
  border-color: #fe5763 !important;
  border-style: solid;
  border-width: 0 0.1em 0.1em 0;
  height: 0.4em;
  right: 45px;
  margin-top: 2px !important;
  transform: rotate(45deg);
  width: 0.4em;
}

.clamp-lines__button_profile[aria-expanded="true"]::before {
  content: " ";
  position: absolute;
  border-color: #fe5763 !important;
  border-style: solid;
  border-width: 0 0.1em 0.1em 0;
  height: 0.4em;
  left: 78px;
  margin-top: 6px !important;
  transform: rotate(-135deg);
  width: 0.4em;
}

/* Fix Search bar bugs */

.am-search-synthetic-ph {
  width: 81px !important;
  padding-left: 15px !important;
}

/* Fix Desktop bug Search Bar */
.am-search {
  max-width: 414px !important;
  margin: 0px auto;
  padding: 20px 0 !important;
}

.am-search-input {
  width: unset !important;
  max-width: 414px !important;
  margin: 0 10px !important;
}

/* Share Bottom Sheet */

section.web-share-fade-in-up {
  max-width: 414px !important;
  width: 100% !important;
}

section.web-share-fade-in-up div {
  padding: 10px 30px !important;
  margin-bottom: 30px;
}

section.web-share-fade-in-up header {
  padding: 30px 30px !important;
}

/* Icon Wrapper Share */

.iconWrapperShare:active {
  background: #ff8800 !important;
}

/* Image Viewer max height */

.img_pc9x2k-o_O-imgLoaded_9vayoh {
  max-height: 70vh !important;
}

/* Animation Shaking Blender */

.shake-blender {
  animation: leaves 0.15s ease-in-out infinite;
  -webkit-animation: leaves 0.15s ease-in-out infinite;
  transform-origin: top left;
}

@keyframes leaves {
  0% {
    transform: rotate(0deg) translate(-50%, -50%);
    -webkit-transform: rotate(0deg) translate(-50%, -50%);
  }

  50% {
    transform: rotate(10deg) translate(-50%, -50%);
    -webkit-transform: rotate(10deg) translate(-50%, -50%);
  }

  75% {
    transform: rotate(-10deg) translate(-50%, -50%);
    -webkit-transform: rotate(-10deg) translate(-50%, -50%);
  }

  100% {
    transform: rotate(0deg) translate(-50%, -50%);
    -webkit-transform: rotate(0deg) translate(-50%, -50%);
  }
}

/* Basic fade in animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Profile Headline */

.profile-headline {
  font-size: 16px;
  color: #212121;
  font-family: "Poppins";
  font-weight: 600;
}

/* To force word to line-return */
.force-wrap {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
}
